import React from "react"
import { Link, graphql } from "gatsby"
import scrollToElement from "scroll-to-element"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from "react-slick"
import ContactMapRow from "../components/contactMapRow"

const IndexPage = ({ data }) => {
  const settingsSlider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    pauseOnHover: false,
  }

  return (
    <Layout>
      <Seo title="Hjem" />

      <section className="home_top_back">
        <Slider {...settingsSlider}>
          {data?.home?.acfHome?.heroContent?.map((item, index) => (
            <div key={index}>
              <div
                className="slick-home-wrapper"
                style={{
                  backgroundImage: `url(${item?.background?.localFile?.publicURL})`,
                }}
              >
                <div className="slick-home-inner">
                  <h2>
                    <div dangerouslySetInnerHTML={{ __html: item?.header }} />
                  </h2>
                  <Link to={item?.link} className="btn_custom">
                    Les mer
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <button
          className="home_top_back--scroll"
          onClick={() => scrollToElement("#mer")}
          aria-label="Les mer"
        ></button>
      </section>

      <section className="home_section_tiles" id="mer">
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            {data?.home?.acfHome?.tilesContent?.map((item, index) => (
              <div className="col-lg-4 col-sm-6" key={index}>
                <div className="home_section_tiles-tile">
                  <h3>{item.header}</h3>
                  <p>{item.description}</p>
                  <div className="image_wrapper">
                    <img
                      className="img-fluid"
                      src={item?.image?.localFile?.publicURL}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="home_section_prodcts_v2">
        <span className="custom_shape"></span>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <h2 className="section_header">
                Hva skal <br /> du sikre?
              </h2>
            </div>
            <div className="col-lg-8">
              {data?.products?.nodes
                ?.filter(item => item.parentId === null)
                .map((item, index) => (
                  <Link to={item.uri} className="home-product-tile" key={index}>
                    <div className="row">
                      <div className="col-sm-4">
                        <h4>{item.title}</h4>
                      </div>
                      <div className="col-sm-8">
                        <img
                          className="img-fluid"
                          src={
                            require(`../assets/images/home-product-${
                              index + 1
                            }.png`).default
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </section>

      <section className="home_section_map">
        <div className="container-fluid">
          <ContactMapRow data={data?.contact?.acfContact} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    home: wpPage(id: { eq: "cG9zdDoxNA==" }) {
      acfHome {
        heroContent {
          background {
            localFile {
              publicURL
            }
          }
          header
          link
        }
        tilesContent {
          image {
            localFile {
              publicURL
            }
          }
          header
          description
        }
      }
    }
    contact: wpPage(id: { eq: "cG9zdDoxNw==" }) {
      acfContact {
        header
        content
        persons {
          image {
            localFile {
              publicURL
            }
          }
          fullName
          email
          phone
          position
          main
        }
      }
    }
    products: allWpProdukter(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        parentId
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default IndexPage
