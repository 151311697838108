import React from "react"
import ContactMap from "./contactMap"

const ContactMapRow = ({ data }) => {
  return (
    <div className="row custom_contact_row">
      <div className="col-xl-3 col-sm-6 col_1">
        <h2 className="section_header">{data?.header}</h2>
        <div className="section_desc">
          <div dangerouslySetInnerHTML={{ __html: data?.content }} />
          <img src={require("../assets/images/kiwa-iso.svg").default} alt="" />
        </div>
      </div>
      <div className="col-xl-5 col-sm-6 col_2">
        <div className="map_wrapper">
          <div className="map_target">
            <ContactMap />
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-sm-6 col_3">
        <div className="person-list">
          {data?.persons?.map((item, index) => (
            <div className="person-tile" key={index}>
              <div className="row">
                <div className="col-4">
                  {item?.image?.localFile?.publicURL && (
                    <img
                      className="img-fluid"
                      src={item?.image?.localFile?.publicURL}
                      alt={item.fullName}
                    />
                  )}
                </div>
                <div
                  className={
                    item?.image?.localFile?.publicURL ? "col-8" : "col-12"
                  }
                >
                  {item.main ? (
                    <h4>
                      <strong>{item.fullName}</strong>
                    </h4>
                  ) : (
                    <h4>{item.fullName}</h4>
                  )}
                  <p>{item.position}</p>
                  <a href={`tel:${item.phone}`}>{item.phone}</a>
                  <a href={`mailto:${item.email}`}>{item.email}</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ContactMapRow
